import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { LANG } from '../config/i18n';

import classes from './Partners.module.css';

const Partners = () => {
  const locale = localStorage.getItem('locale')
    ? localStorage.getItem('locale')
    : 'ka';

  return (
    <div className={classes.container}>
      <h2>{LANG[locale].partners}</h2>

      <div className="mt20px mb20px">
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-top`}>RURALTOUR</Tooltip>}
        >
          <a href="https://www.ruraltour.eu/">
            <img src="images/greenpartners/ruraltour.png" alt="" />
          </a>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-top`}>GEORGIA TRAVEL</Tooltip>}
        >
          <a href="https://georgia.travel/">
            <img src="images/greenpartners/georgia-travel.png" alt="" />
          </a>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id={`tooltip-top`}>GEORGIAN TOURISM ASSOCIATION</Tooltip>
          }
        >
          <a href="https://www.tourism-association.ge/">
            <img src="images/greenpartners/gta.png" alt="" />
          </a>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-top`}>SAMTSKHE-JAVAKHETI</Tooltip>}
        >
          <a href="https://visitsj.ge/">
            <img
              src="images/greenpartners/samtskhe-javakheti.png"
              alt=""
            />
          </a>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-top`}>ILIAUNI</Tooltip>}
        >
          <a href="https://iliauni.edu.ge/ge">
            <img src="images/greenpartners/iliauni.png" alt="" />
          </a>
        </OverlayTrigger>
      </div>
    </div>
  );
};

export default Partners;

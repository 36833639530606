import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { REMOTE_HOST_URL } from '../config/site';
import { LANG } from '../config/i18n';
import { Button } from 'react-bootstrap';
import { touristyObjectActions } from '../store/touristy-object-slice';

import classes from './SearchFilter.module.css';

const SearchFilter = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  axios.defaults.baseURL = REMOTE_HOST_URL;

  let [types, setTypes] = useState([]);
  let [categories, setCategories] = useState([]);
  let [regions, setRegions] = useState([]);
  let [offers, setOffers] = useState([]);
  const locale = localStorage.getItem('locale')
    ? localStorage.getItem('locale')
    : 'ka';

  const typeRef = useRef();
  const categoryRef = useRef();
  const regionRef = useRef();
  const offerRef = useRef();

  const getTypes = useCallback(async () => {
    let response = axios('/api/touristyobject/type/', {
      headers: {
        'Accept-language': locale
      }
    });
    let result = await response;
    let data = JSON.parse(result.data);
    setTypes(data);
  }, [locale]);

  const getCategories = useCallback(async () => {
    let response = axios('/api/touristyobject/category/', {
      headers: {
        'Accept-language': locale
      }
    });
    let result = await response;
    let data = JSON.parse(result.data);
    setCategories(data);
  }, [locale]);

  const getRegions = useCallback(async () => {
    let response = axios(`/api/touristyobject/region`, {
      headers: {
        'Accept-language': locale
      }
    });
    let result = await response;
    let data = JSON.parse(result.data);
    setRegions(data);
  }, [locale]);

  const getOffers = useCallback(async () => {
    let response = axios('/api/touristyobject/offer/', {
      headers: {
        'Accept-language': locale
      }
    });
    let result = await response;
    let data = JSON.parse(result.data);
    setOffers(data);
  }, [locale]);

  const searchObjects = () => {
    const typeId = typeRef.current.value;
    const categoryId = categoryRef.current.value;
    const regionId = regionRef.current.value;
    const offerId = offerRef.current.value;
    
    dispatch(touristyObjectActions.searchObjects({
      search: true,
      data: {
        typeId,
        categoryId,
        regionId,
        offerId
      }
    }));

    navigate('/objects/');
        
  };

  useEffect(() => {
    getTypes();
    getCategories();
    getRegions();
    getOffers();
  }, [getCategories, getOffers, getRegions, getTypes]);

  return (
    <div className={classes.container}>
      <select
        id="region"
        ref={regionRef}
        className="form-control w250px inline-block"
      >
        <option value="" default defaultValue>
          {LANG[locale].region}
        </option>
        {regions.map(region => {
          return (
            <option key={region.id} value={region.id}>
              {region.name}
            </option>
          );
        })}
      </select>
      <select
        id="type"
        ref={typeRef}
        className="form-control w250px ml7px inline-block"
      >
        <option value="" default defaultValue>
          {LANG[locale].placementType}
        </option>
        {types.map(type => {
          return (
            <option key={type.id} value={type.id}>
              {type.title}
            </option>
          );
        })}
      </select>
      <select
        id="category"
        ref={categoryRef}
        className="form-control w250px  ml7px inline-block"
      >
        <option value="" default defaultValue>
          {LANG[locale].category}
        </option>
        {categories.map(category => {
          return (
            <option key={category.id} value={category.id}>
              {category.title}
            </option>
          );
        })}
      </select>

      <select
        id="offer"
        ref={offerRef}
        className="form-control w250px ml7px inline-block"
      >
        <option value="" default defaultValue>
          {LANG[locale].offer}
        </option>
        {offers.map(offer => {
          return (
            <option key={offer.id} value={offer.id}>
              {offer.title}
            </option>
          );
        })}
      </select>
      <Button
        variant="btn btn-success border"
        onClick={searchObjects}
        className="inline-block ml10px"
      >
        <i className="fa fa-search"></i>
      </Button>
    </div>
  );
};

export default SearchFilter;

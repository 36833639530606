import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

import axios from 'axios';
import { REMOTE_HOST_URL } from '../../config/site';

import classes from './GoogleBasedMap.module.css';

axios.defaults.baseURL = REMOTE_HOST_URL;

const center = { lat: 41.702455, lng: 44.815505 };

function GoogleBasedMap() {
  const [touristyObjects, setTouristyObjects] = useState([]);

  useEffect(() => {
    touristyObjectsHandler();
  }, []);

  const touristyObjectsHandler = async () => {
    const response = axios({
      method: 'post',
      url: '/api/touristyobject/',
      headers: {
        Authorization: 'Bearer-token'
      },
      data: {
        short_only: true
      }
    });
    const result = await response;
    const data = JSON.parse(result.data);
    setTouristyObjects(data);
  };

  return (
    <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
      <GoogleMap
        mapContainerClassName={classes['map-container']}
        center={center}
        zoom={8}
      >
        {touristyObjects &&
          touristyObjects.map(obj => {
            if (obj['google_map_coordinates']) {
              let coords = {
                lat: +obj['google_map_coordinates']['latitude'],
                lng: +obj['google_map_coordinates']['longitude']
              };
              return (
                <Marker
                  position={coords}
                  key={obj['id']}
                  title={obj['title']}
                  info={obj['title']}
                  cursor="pointer"
                />
              );
            }
            return null;
          })}
      </GoogleMap>
    </LoadScript>
  );
}

export default React.memo(GoogleBasedMap);

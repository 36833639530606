import { useDispatch } from 'react-redux';
import classes from './NavBar.module.css';
import { Link, useNavigate } from 'react-router-dom';
import { LANG } from '../config/i18n';
import { Button } from 'react-bootstrap';
import { touristyObjectActions } from '../store/touristy-object-slice';

function NavBar({ title, onLanguageChange, onLinkClick }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const locale = localStorage.getItem('locale')
    ? localStorage.getItem('locale')
    : 'ka';

  const quickSearchHandler = () => {
    dispatch(
      touristyObjectActions.searchObjects({
        search: true,
        data: {
          searchText: document.getElementById('quick-search-input').value
        }
      })
    );
    document.getElementById('quick-search-input').value = '';
    navigate('/objects/');
  };

  const listOfFarmsHandler = (event) => {
    event.preventDefault();

    dispatch({ type: 'search', data: {} });
    window.location.href = '/objects/region';
  };

  const onLinkClickHandler = (name) => {
    onLinkClick(name);
    hideDropdown();
  };

  const dropdownMenu = (event) => {
    event.target
        .closest('div')
        .querySelector('.dropdown-content')
        .classList.toggle('show');
  };

  const hideDropdown = () => {
    document.querySelectorAll('.dropdown-content').forEach((item) => {
      item.classList.remove('show');
    });
  };

  return (
    <div className={classes.container}>
      <div className={classes.logo}>
        <Link to="/" onClick={onLinkClickHandler.bind(null, 'home')}>
          <img src="/images/logo-en.png" alt="" className={classes.logo}></img>
        </Link>
      </div>
      <div className={classes.main}>
        <div className={classes['top-wrapper']}>
          

          <div className={`${classes['nav-links']}`}>
            <Link
              to="/article/category/64"
              className={classes.button}
              onClick={onLinkClickHandler.bind(null, 'about_us')}
            >
              {LANG[locale]['aboutUs']}
            </Link>
            <Link
              to="/news"
              className={classes.button}
              onClick={onLinkClickHandler.bind(null, 'news')}
            >
              {LANG[locale]['news']}
            </Link>
            <Link
              to="/contact"
              className={`${classes.button} ${classes.end}`}
              onClick={onLinkClickHandler.bind(null, 'contact')}
            >
              {LANG[locale]['contact']}
            </Link>
          </div>
          <div className={`${classes.actions} input-group`}>
            <input
              type="text"
              className={`form-control ${classes['search-input']}`}
              id="quick-search-input"
            />
            <Button variant="btn" type="button" onClick={quickSearchHandler}>
              <i className="fa fa-search" aria-hidden="true"></i>
            </Button>
          </div>
          <div className={`${classes.section3}`}>
            
            <div className={`${classes['list-of-farm']}  text-success mt-2`}>
              <i className="fa fa-map-marker" aria-hidden="true"></i>{' '}
              <div
                className="d-inline cursor-pointer"
                onClick={listOfFarmsHandler}
              >
                Lists of farms
              </div>
            </div>

            <div className={`${classes['locale-switch']}`}>
              <div
                className="btn text-success"
                onClick={onLanguageChange.bind(this, 'ka')}
              >
                GEO
              </div>
              <span>/</span>
              <div
                className="btn text-success"
                onClick={onLanguageChange.bind(this, 'en')}
              >
                ENG
              </div>
            </div>
          </div>
        </div>
        <div className={classes['bottom-wrapper']}>
          <Link
            to="/holidays"
            className={`${classes['nav-menu-block']} ${classes.item1}`}
            onClick={onLinkClickHandler.bind(null, 'holidays')}
          >
            {LANG[locale].relaxInFamilyHotelsAndAgrotourismFarms}
          </Link>
          <Link
            to="/category"
            className={`${classes['nav-menu-block']} ${classes.item2}`}
            onClick={onLinkClickHandler.bind(null, 'category')}
          >
            {LANG[locale].categoriesOfQualityOfServices}
          </Link>
          {/* <Link
            to="/offers"
            className={`${classes['nav-menu-block']} ${classes.item3}`}
            onClick={onLinkClickHandler.bind(null, 'offers')}
          >
            {LANG[locale].offersForYou}
          </Link> */}
          <div
            className={`${classes['nav-menu-block']} ${classes['medium']} ${classes.item4} dropdown`}
          >
            <button className="dropdown-button" onClick={dropdownMenu}>
              {LANG[locale].menu.elkana_conservation_farms}
            </button>
            <div className="dropdown-content">
              <Link
                to="/touristyobject/24"
                data-url="/touristyobject/24"
                onClick={hideDropdown}
              >
                {
                  LANG[locale].menu.elkana_conservation_farms__items
                    .elkana_conservation_farm_grain_ark
                }
              </Link>
              <Link
                to="/touristyobject/4"
                data-url="/touristyobject/4"
                onClick={hideDropdown}
              >
                {
                  LANG[locale].menu.elkana_conservation_farms__items
                    .elkana_pet_conservation_farm
                }
              </Link>
              <Link to="/article/category/70" onClick={hideDropdown}>
                {
                  LANG[locale].menu.elkana_conservation_farms__items
                    .agrotour
                }
              </Link>
            </div>
          </div>
          <Link
            to="/destinations"
            className={`${classes['nav-menu-block']} ${classes.item4}`}
            onClick={onLinkClickHandler.bind(null, 'destinations')}
          >
            {LANG[locale].map}
          </Link>
          <a
            href="https://www.ruraltour.eu"
            className={`${classes['nav-end-logo']} ${classes.logo1}`}
          >
            <img src="/images/greenpartners/ruraltour.png" alt="" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default NavBar;

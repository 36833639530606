import { useParams } from 'react-router-dom';
import axios from 'axios';
import { REMOTE_HOST_URL } from '../config/site';
import StaticInteractiveMap from '../component/Map/StaticInteractiveMap';
import { useEffect } from 'react';

axios.defaults.baseURL = REMOTE_HOST_URL;

const Destinations = ({ linkName }) => {
  const params = useParams();
  const regionId = params.regionId;

  let content = '';
  if (regionId) {
    content = <div>Region ID : {regionId}</div>;
  } else {
    content = (
      <h1>
        <br />
      </h1>
    );
  }

  let regionsContent = '';

  useEffect(()=>{
    if (window.innerWidth < 640 && linkName === 'destinations') {
      const container = document.querySelector('.container');
      window.scrollTo(0, container.offsetTop);
    }
  },[linkName])

  return (
    <>
      <div className="container">
        {content}
        <StaticInteractiveMap />
        {regionsContent}
      </div>
    </>
  );
};

export default Destinations;

import React, { Suspense, useState } from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from './Pages/Home';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './shared.css';

import Destinations from './Pages/Destinations';
import GoogleBasedMap from './component/Map/GoogleBasedMap';
// import Footer from './component/Footer';

import Layout from './UI/Layout';
import OffersPage from './Pages/Offers.js';

const TouristyObjectsPage = React.lazy(() => import('./Pages/TouristyObjects'));
const TouristyObjectPage = React.lazy(() => import('./Pages/TouristyObject'));
const HolidaysPage = React.lazy(() => import('./Pages/Holidays'));
const CategoryPage = React.lazy(() => import('./Pages/Category'));
const ArticlePage = React.lazy(() => import('./Pages/Article.js'));
const ContactPage = React.lazy(() => import('./Pages/Contact.js'));
const News = React.lazy(() => import('./Pages/News'));

function App() {
  const state = useState();
  const [linkClickTarget, setLinkClickTarget] = useState('');

  return (
    <Layout linkClickProps={setLinkClickTarget}>
      <Suspense fallback={<p></p>}>
        <Routes>
          <Route path="/" element={<Home linkName={linkClickTarget} linkClickProps={setLinkClickTarget} />} />
          <Route path="/holidays" element={<HolidaysPage linkName={linkClickTarget} />} />
          <Route
            path="/objects/region/:regionId"
            element={<TouristyObjectsPage  linkName={linkClickTarget} linkClickProps={setLinkClickTarget} />}
          />
          <Route path="/objects/region" element={<TouristyObjectsPage  linkName={linkClickTarget} />} />
          <Route
            path="/objects/"
            data={state.data}
            element={
              <TouristyObjectsPage search={state.search ? state.data : ''} linkName={linkClickTarget} linkClickProps={setLinkClickTarget} />
            }
          />
          <Route path="/touristyobject/:id" element={<TouristyObjectPage linkName={linkClickTarget} />} />
          <Route path="/category" element={<CategoryPage linkName={linkClickTarget} />} />
          <Route path="/offers" element={<OffersPage linkName={linkClickTarget} />} />
          <Route path="/destinations" element={<Destinations linkName={linkClickTarget} />} />
          <Route
            path="/destinations/region/:regionId"
            element={<Destinations linkName={linkClickTarget} />}
          />
          <Route
            path="/article/category/:categoryId"
            element={<ArticlePage linkName={linkClickTarget} />}
          />
          <Route path="/article/:articleId" element={<ArticlePage linkName={linkClickTarget} />} />
          <Route path="/region" element={<GoogleBasedMap linkName={linkClickTarget} />} />
          <Route path="/contact" element={<ContactPage linkName={linkClickTarget} />} />
          <Route path="/news" element={<News linkName={linkClickTarget} />} />
          <Route path="/news/:id" element={<News linkName={linkClickTarget} />} />
        </Routes>
      </Suspense>
    </Layout>
  );
}

export default App;

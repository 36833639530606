import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

import classes from './Offers.module.css';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { REMOTE_HOST_URL } from '../config/site';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { touristyObjectActions } from '../store/touristy-object-slice';

const Offers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [icons, setIcons] = useState(null);
  const locale = localStorage.getItem('locale')
    ? localStorage.getItem('locale')
    : 'ka';

  const getIcons = useCallback(async () => {
    let options = {
      method: 'get',
      url: '/api/touristyobject/offer/',
      headers: {
        'Accept-language': locale
      }
    };

    let response = axios(options);
    let result = await response;
    let data = JSON.parse(result.data);

    setIcons(data);
  }, [locale]);

  const offerHandler = (event) => {
    dispatch(
      touristyObjectActions.searchObjects({
        search: true,
        data: {
          offerId: event.target.dataset.id
        }
      })
    );

    navigate('/objects');
  };

  useEffect(() => {
    getIcons();
  }, [getIcons]);

  return (
    <div className={classes['featured-objects']}>
      {icons &&
        icons.map((item) => {
          return (
            <div className={classes['featured-objects-item']} key={item.id}>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id={`tooltip-top`}>{item.title}</Tooltip>}
              >
                <img
                  className={classes['featured-objects-icon']}
                  onClick={offerHandler}
                  data-id={item.id}
                  src={`${REMOTE_HOST_URL}/uploads/touristy-object/offer/icons/${item.icon}`}
                  alt=""
                />
              </OverlayTrigger>
            </div>
          );
        })}
    </div>
  );
};

export default Offers;

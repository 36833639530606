import axios from 'axios';

import OFfers from '../component/Offers';

import { REMOTE_HOST_URL } from '../config/site';
import { LANG } from '../config/i18n';

import classes from './Offers.module.css';
import { useEffect } from 'react';

axios.defaults.baseURL = REMOTE_HOST_URL;

const OffersPage = ({linkName}) => {
  
  const locale = localStorage.getItem('locale')
    ? localStorage.getItem('locale')
    : 'ka';

  useEffect(() => {
    if (window.innerWidth < 640 && linkName === 'offers') {
      const container = document.querySelector('.container');
      window.scrollTo(0, container.offsetTop);
    }
  }, [linkName]);

  return (
    <div className={`container ${classes.container}`}>
      <br />
      <h3 className="text-center mt-4">{LANG[locale].offers}</h3>
      <br />
      <OFfers />
    </div>
  );
};

export default OffersPage;

import React from 'react';
import { Link } from 'react-router-dom';
import {LANG} from '../config/i18n';

import classes from './Footer.module.css';

const Footer = () => {

  const locale = localStorage.getItem('locale')
    ? localStorage.getItem('locale')
    : 'ka';

  return (
    <div className={classes.footer}>
      <ul>
        <li>
          <a href="https://www.elkana.org.ge">{LANG[locale].mainPage}</a>
        </li>
        <li>
          <Link to="/article/category/64" className="text-light">
            {LANG[locale].elkana_sRuralTourismNetwork}
          </Link>
        </li>
        <li><Link to='/article/category/79' className="text-light">{LANG[locale].toJoinElkana}</Link></li>        
      </ul>

      <ul>
        <li><Link to='/holidays'>{LANG[locale].ruralHousesAndAgrotourismFarms}</Link></li>
        <li>
          <Link to="/article/category/75" className="text-light">
          {LANG[locale].serviceStandarCategories}
          </Link>
        </li>
        <li><Link to="/offers" className="text-light">{LANG[locale].offers}</Link></li>
        <li><Link to="/destinations" className="text-light">{LANG[locale].regions}</Link></li>
        <li><Link to="/contact" className="text-light">{LANG[locale].contact}</Link></li>
      </ul>
    </div>
  );
};

export default Footer;

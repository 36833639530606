import { Fragment, useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';

import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import axios from 'axios';
import { REMOTE_HOST_URL } from '../config/site';
import { LANG } from '../config/i18n';

import Offers from '../component/Offers';
import SearchFilter from '../component/SearchFilter';
import StaticInteractiveMap from '../component/Map/StaticInteractiveMap';

import Loading from '../UI/Loading';
import classes from './Home.module.css';
import { Link } from 'react-router-dom';

import { useCallback } from 'react';
import Partners from '../component/Partners';

function HomePage({ linkName, linkClickProps }) {
  axios.defaults.baseURL = REMOTE_HOST_URL;

  let [tourismData, setTourismData] = useState([]);
  let [isLoading, setIsLoading] = useState(false);

  const locale = localStorage.getItem('locale')
    ? localStorage.getItem('locale')
    : 'ka';

  const closeLoadingHandler = () => {
    setIsLoading(false);
  };
  const touristyObjects = useCallback(async () => {
    let options = {
      method: 'post',
      url: '/api/touristyobject/',
      headers: {
        Authorization: 'Bearer-token',
        'Accept-language': locale
      },
      data: {
        resultLimit: 3,
        type: 1
      }
    };

    setIsLoading(true);
    let response = axios(options);
    let result = await response;

    let data = JSON.parse(result.data);

    setTourismData(data);
    setIsLoading(false);
  }, [locale]);

  useEffect(() => {
    touristyObjects();
    if (window.innerWidth < 640 && linkName === 'home') {
      const container = document.querySelector('.container');
      if (container) {
        window.scrollTo(0, container.offsetTop + 15);
      }
    }
  }, [touristyObjects, linkName]);

  const sunItems = (qty) => {
    let suns = [];
    for (let i = 0; i < qty; i++) {
      suns.push('<img src="/images/mze.png" class="items-sun" />');
    }

    return suns;
  };

  const sunHtml = (images, tag) => {
    if (tag === 'span') {
      return (
        <span dangerouslySetInnerHTML={{ __html: `${images.join(' ')}` }} />
      );
    }
    return <div dangerouslySetInnerHTML={{ __html: `${images.join(' ')}` }} />;
  };

  const linkClickHandler = e => {
    linkClickProps(e);
  };

  return (
    <Fragment>
      <div className={`container ${classes.cover}`}>
        <Carousel fade className="mt-3 mx-auto">
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="images/carousel/image1.jpg"
              alt=""
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="images/carousel/image2.jpg"
              alt=""
            />
            <Carousel.Caption></Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>

      <h3 className="pt-2 text-center my-4">
        {LANG[locale].familyGuestHouses}
      </h3>
      <div className={`${classes['card-preview-container']} mt-2 mb-4`}>
        {isLoading ? (
          <Loading onClick={closeLoadingHandler} />
        ) : (
          tourismData &&
          tourismData.map((obj) => {
            let sunImages = sunItems(obj.sun);
            return (
              <div className={classes['card-preview']} key={obj.id}>
                <div className={classes['card-preview-image-container']}>
                  <Link
                    to={`/touristyobject/${obj.id}`}
                    className={`${classes['card-link']} text-success`}
                    onClick={linkClickHandler.bind(null, 'object')}
                  >
                    <img
                      className="lazyload"
                      src={`${REMOTE_HOST_URL}/uploads/touristy-object/photo/${obj.photo}`}
                      alt=""
                    />
                  </Link>
                  <div className={`${classes['card-preview-title']}`}>
                    {obj.title}
                  </div>
                </div>

                <div className={classes['card-preview-body']}>
                  <div className="display-inline mt-1">
                    <span className="text-muted">
                      {LANG[locale].location}:{' '}
                    </span>
                    <span className="text-dark">{obj.region_name}</span>
                    {obj.sun && (
                      <Fragment>
                        <br />
                        <span className="text-muted">
                          {LANG[locale].category}: {sunHtml(sunImages, 'span')}{' '}
                        </span>
                      </Fragment>
                    )}
                  </div>

                  <p className={classes['card-preview-text']}>
                    <Link
                      to={`/touristyobject/${obj.id}`}
                      className={`${classes['card-link']} text-success`}
                      onClick={linkClickHandler.bind(null, 'object')}
                    >
                      {' '}
                      <span className="text-success">
                        {LANG[locale].moreDetail}&nbsp;...
                      </span>
                    </Link>
                  </p>
                </div>
              </div>
            );
          })
        )}
      </div>
      <br />
      <h3 className="text-center mt-4 mb-4">{LANG[locale].offers}</h3>
      <Offers />
      <br />
      <h3 className="text-success text-center">
        {LANG[locale].chooseFamilyGuestHouseAndAgrotouristicFarming}
      </h3>
      <SearchFilter />

      <br />

      <StaticInteractiveMap />
      <br />
      <br />
      <Partners />
    </Fragment>
  );
}

export default HomePage;

import { createSlice } from '@reduxjs/toolkit';

const newsSlice = createSlice({
  name: 'news',
  initialState: {
    listItems: [],
    newsItem: {}
  },
  reducers: {
    fillNewsList(state, action) {
      state.listItems = action.payload;
    },
    fillNewsDetails(state, action) {
      state.newsItem = action.payload;
    }
  }
});

export const newsActions = newsSlice.actions;

export default newsSlice;

// import {
//   legacy_createStore,
//   applyMiddleware,
//   combineReducers
// } from '@reduxjs/toolkit';
// import thunk from 'redux-thunk';

// import { newsListReducer, newsDetailReducer } from './reducers/newsReducers';
// import { touristyObjectSearchReducer } from './reducers/touristyObjectReducers';

// const reducer = combineReducers({
//   newsList: newsListReducer,
//   newsDetail: newsDetailReducer,
//   touristyObjectSearch: touristyObjectSearchReducer
// });

// const store = legacy_createStore(reducer, applyMiddleware(thunk));

// export default store;

import { configureStore } from '@reduxjs/toolkit';
import newsSlice from './news-slice';
import touristyObjectSlice from './touristy-object-slice';

const store = configureStore({
  reducer: {
    news: newsSlice.reducer,
    routistyObject: touristyObjectSlice.reducer
  }
});
export default store;
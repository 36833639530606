export const LANG = {
  ka: {
    familyGuestHouses: 'საოჯახო სასტუმრო სახლები',
    chooseFamilyGuestHouseAndAgrotouristicFarming:
      'შეარჩიეთ საოჯახო სასტუმრო სახლი და აგროტურისტული მეურნეობა',
    location: 'მდებარეობა',
    category: 'კატეგორია',
    moreDetail: 'მეტი დეტალი',
    traditionalDishesAndUniqueRecipes:
      'ტრადიციული კერძები და უნიკალური რეცეფტები',
    ecologicalAgrotourismFarms: 'ეკოლოგიური აგროტურისტული მეურნეობები',
    offers: 'შეთავაზებები',
    news: 'სიახლეები',
    usefulInformation: 'სასარგებლო ინფორმაცია',
    map_section: {
      searchForYourDesiredFamilyGuestHouseAndAgritourismFarm:
        'მოძებნეთ თქვენთვის სასურველი საოჯახო სასტუმრო სახლი და<br /> აგროტურისტული მეურნეობა'
    },
    region_section: {
      abkhazia: 'აფხაზეთი',
      svaneti: 'სვანეთი',
      samegrelo: 'სამეგრელო',
      'samegrelo-zemo-svaneti': 'სამეგრელო ზემო-სვანეთი',
      racha: 'რაჭა',
      'racha-lechkhumi-kvemo-svaneti': 'რაჭა, ლეჩხუმი, ქვემო-სვანეთი',
      guria: 'გურია',
      adjara: 'აჭარა',
      imereti: 'იმერეთი',
      'samtskhe-javakheti': 'სამცხე-ჯავახეთი',
      samtskheJavakheti: 'სამცხე-ჯავახეთი',
      'shida-kartli': 'შიდა ქართლი',
      shidaKartli: 'შიდა ქართლი',
      'kvemo-kartli': 'ქვემო ქართლი',
      kvemoKartli: 'ქვემო ქართლი',
      kazbegi: 'ყაზბეგი',
      khevsureti: 'ხევსურეთი',
      'mtskheta-mtianeti': 'მცხეთა-მთიანეთი',
      mtskhetaMtianeti: 'მცხეთა-მთიანეთი',
      tbilisi: 'თბილისი',
      tusheti: 'თუშეთი',
      kakheti: 'კახეთი'
    },
    contact: 'კონტაქტი',
    contact_section: {
      address: 'გაზაფხულის ქ. #61, თბილისი 0186, საქართველო',
      join_us: 'შემოგვიერთდით'
    },
    partners: 'პარტნიორები',
    relaxInFamilyHotelsAndAgrotourismFarms:
      'დაისვენეთ საოჯახო სასტუმროებსა და აგროტურისტულ მეურნეობებში',
    categoriesOfQualityOfServices: 'მომსახურებების ხარისხის კატეგორიები',
    offersForYou: 'შეთავაზებები თქვენთვის',
    map: 'რუკა',
    region: 'რეგიონი',
    placementType: 'ობიექტი',
    offer: 'შეთავაზება',
    howToGetServiceQualityMark: 'როგორ მივიღოთ მომსახურების ხარისხის ნიშანი?',
    standardForFamilyGuestHousesAndAgrotourismFarms:
      'ელკანას სტანდარტი საოჯახო სასტუმრო სახლებისა და აგროტურისტული მეურნეობებისათვის',
    showOnGoogleMap: 'მაჩვენე Google Map-ზე',
    serviceStandarCategories: 'მომსახურების სტანდარტი, კატეგორიები',
    elkana_sRuralTourismNetwork: '„ელკანას“ სოფლის ტურიზმის ქსელი',
    toJoinElkana: 'ელკანაში გაწევრიანება',
    ruralHousesAndAgrotourismFarms:
      'სოფლის სახლები და აგროტურისტული მეურნეობები',
    mainPage: 'მთავარი გვერდი',
    categoryPageContent: `<p>საქართველოში სოფლად ტურისტული მომსახურების სტაბილური ხარისხის დანერგვის ხელშეწყობის მიზნით "ელკანამ" შეიმუშავა სტანდარტი - „საოჯახო სასტუმრო სახლები სოფლად და პატარა ქალაქებში“ (მსტ 20545291-02-2008). 2021 წელს, აღნიშნული სტანდარტის დოკუმენტს დაემატა მოთხოვნები აგროტურისტული მეურნეობების მიმართ.</p>
    <p>"ელკანას" სტანდარტი გულისხმობს განთავსების ობიექტების დაჯგუფებას 4 კატეგორიად, რომელთა ხარისხის დონის შესაფასებლად გამოიყენება სიმბოლური ნიშანი "მზე".</p>`,
    aboutUs: 'ჩვენს შესახებ',
    menu: {
      'elkana_conservation_farms': 'ელკანას საკონსერვაციო მეურნეობები',
      'elkana_conservation_farms__items': {
        'elkana_conservation_farm_grain_ark': 'ელკანა საკონსერვაციო მეურნეობა მარცვლეულის კიდობანი',
        'elkana_pet_conservation_farm': 'ელკანას შინაურ ცხოველთა საკონსერვაციო მეურნოება',
        'agrotour': 'აგროტური'
      }
    }
  },
  en: {
    familyGuestHouses: 'Family Guest Houses',
    chooseFamilyGuestHouseAndAgrotouristicFarming:
      'Choose a family guest house and an agro-tourism farm',
    location: 'Location',
    category: 'Category',
    moreDetail: 'More detail',
    traditionalDishesAndUniqueRecipes: 'Traditional dishes and unique recipes',
    ecologicalAgrotourismFarms: 'Ecological agro-tourism farms',
    offers: 'Offers',
    news: 'News',
    usefulInformation: 'Useful information',
    map_section: {
      searchForYourDesiredFamilyGuestHouseAndAgritourismFarm:
        'Search for your desired family guest house and agritourism farm'
    },
    region_section: {
      abkhazia: 'Abkhazia',
      svaneti: 'Svaneti',
      samegrelo: 'Samegrelo',
      'samegrelo-zemo-svaneti': 'Samegrelo Zemo-Svaneti',
      racha: 'Racha',
      'racha-lechkhumi-kvemo-svaneti': 'Racha, Lechkhumi, Kvemo-შvaneti',
      guria: 'Guria',
      adjara: 'Adjara',
      imereti: 'Imereti',
      'samtskhe-javakheti': 'Samtskhe-Javakheti',
      samtskheJavakheti: 'Samtskhe-Javakheti',
      'shida-kartli': 'Shida Kartli',
      shidaKartli: 'Shida Kartli',
      'kvemo-kartli': 'Kvemo Kartli',
      kvemoKartli: 'Kvemo Kartli',
      kazbegi: 'Kazbegi',
      khevsureti: 'Khevsureti',
      'mtskheta-mtianeti': 'Mtskheta-Mtianeti',
      mtskhetaMtianeti: 'Mtskheta-Mtianeti',
      tbilisi: 'Tbilisi',
      tusheti: 'Tusheti',
      kakheti: 'Kakheti'
    },
    contact: 'Contact',
    contact_section: {
      address: '61 Gazapkhuli str, Tbilisi 0186, Georgia',
      join_us: 'Join us'
    },
    partners: 'Partners',
    relaxInFamilyHotelsAndAgrotourismFarms:
      'Relax in family hotels and agro-tourism farms',
    categoriesOfQualityOfServices: 'Categories of quality of services',
    offersForYou: 'Offers for you',
    map: 'Map',
    region: 'Region',
    placementType: 'Type',
    offer: 'Offer',
    howToGetServiceQualityMark: 'How to get service quality mark?',
    standardForFamilyGuestHousesAndAgrotourismFarms:
      "Elkana's standard for family guest houses and agro-tourism farms",
    showOnGoogleMap: 'Show on Google Map',
    serviceStandarCategories: 'Service standard, categories',
    elkana_sRuralTourismNetwork: '"Elkana\'s" Rural Tourism Network',
    toJoinElkana: 'To join Elkana',
    ruralHousesAndAgrotourismFarms: 'Rural houses and agro-tourism farms',
    mainPage: 'Home',
    categoryPageContent: `საქართველოში სოფლად ტურისტული მომსახურების სტაბილური ხარისხის დანერგვის ხელშეწყობის მიზნით "ელკანამ" შეიმუშავა სტანდარტი - „საოჯახო სასტუმრო სახლები სოფლად და პატარა ქალაქებში“ (მსტ 20545291-02-2008). 2021 წელს, აღნიშნული სტანდარტის დოკუმენტს დაემატა მოთხოვნები აგროტურისტული მეურნეობების მიმართ.<br />
    "ელკანას" სტანდარტი გულისხმობს განთავსების ობიექტების დაჯგუფებას 4 კატეგორიად, რომელთა ხარისხის დონის შესაფასებლად გამოიყენება სიმბოლური ნიშანი "მზე".`,
    aboutUs: 'About Us',
    menu: {
      'elkana_conservation_farms': 'Elkana Conservation Farms',
      'elkana_conservation_farms__items': {
        'elkana_conservation_farm_grain_ark': 'Elkana Seed Ark Farm',
        'elkana_pet_conservation_farm': 'Elkana Conservation Farm of Domestic Animals',
        'agrotour': 'Agrotour'
      }
    }
  }
};

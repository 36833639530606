import NavBar from './NavBar';
import Footer from './Footer';

import classes from './Layout.module.css';

const Layout = ({ children, linkClickProps }) => {
  const changeLanguageHandler = locale => {
    localStorage.setItem('locale', locale);
    window.location.href = '/';
  };

  const linkClickHandler = e => {
    linkClickProps(e);
    console.log(e);
  };

  return (
    <div className={classes['root-container']}>
      <div className={classes.header}>
        <NavBar
          onLanguageChange={changeLanguageHandler}
          onLinkClick={linkClickHandler}
        />
      </div>
      <div className={classes['main-container']}>{children}</div>
      <div className={classes.footer}>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;

import React from 'react';

import classes from './Loading.module.css';

const Loading = props => {
    return (
        <div onClick={props.onClick} className={classes['loading-container']}>
            
        </div>
    );
};

export default Loading;

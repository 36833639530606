import { createSlice } from '@reduxjs/toolkit';

const touristyObjectSlice = createSlice({
  name: 'touristy_object',
  initialState: {
    data: {
      typeId: '',
      categoryId: '',
      regionId: '',
      offerId: null,
      searchText: null
    },
    search: false
  },
  reducers: {
    searchObjects(state, action) {
      if (action.payload.search) {
        const searchData = {
          typeId: action.payload.data.typeId ? action.payload.data.typeId : '',
          categoryId: action.payload.data.categoryId ? action.payload.data.categoryId : '',
          regionId: action.payload.data.regionId ? action.payload.data.regionId : '',
          offerId: action.payload.data.offerId ? action.payload.data.offerId : null,
          searchText: action.payload.data.searchText ? action.payload.data.searchText : null
        };
        state.data = searchData;
        state.search = true;
      }
    },
    resetSearch: (state) => {
      state.search = false;
    }
  }
});

export const touristyObjectActions = touristyObjectSlice.actions;

export default touristyObjectSlice;
